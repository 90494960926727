<template>
  <span class="base-tag text-xxs md:text-xs font-medium" :class="[computedClass, size]">
    <slot />
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useThemeVariant } from '~/composables';
import type { UiVariant } from '~/types';

const { tagsClassMap } = useThemeVariant();

interface Props {
  variant?: UiVariant,
  size?: 'xs' | 'base'
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'tertiary',
  size: 'base',
});

const computedClass = computed(() => tagsClassMap(props.variant));
</script>

<style scoped>
.base-tag {
  @apply flex-shrink-0 leading-none inline-flex items-center;
  &.base {
    @apply px-2 py-2 rounded-lg;
  }
  &.xs {
    @apply px-2 py-0.5 rounded-full text-xxs uppercase;
  }
}
</style>
